import { Font, StyleSheet } from '@react-pdf/renderer';

Font.register({
	family: 'OpenSans',
	fonts: [{ src: 'https://fonts.googleapis.com/css2?family=Open+Sans&display=swap' }]
});

export const downloadStyles = StyleSheet.create({
	body: {
		width: '100%'
		// display: 'flex',
	},
	page: {
		// display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		backgroundColor: '#fff',
		paddingTop: '20px',
		paddingBottom: '20px',
		paddingRight: '10px',
		paddingLeft: '10px'
	},
	section_header: {
		// display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '80%',
		marginBottom: '30px'
	},
	logo: {
		// display: 'flex',
		width: '80px',
		height: '80px',
		borderRadius: '15px'
	},
	header: {
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: '15px',
		// marginBottom: '50px',
		width: '70%'
	},
	patient_text: {
		fontSize: '11px',
		marginRight: '5px'
		// alignItems: 'end',
	},
	patient_signature_text: {
		fontSize: '15px',
		marginRight: '5px'
		// alignItems: 'end',
	},
	patient_signature_text_underlined: {
		// display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		// flexDirection: 'row',
		width: '120px',
		borderBottom: '1px solid black',
		fontSize: '15px',
		textAlign: 'center'
	},
	section_patient_info: {
		width: '85%',
		marginBottom: '20px',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	patient_text_underlined: {
		// display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		// flexDirection: 'row',
		width: '165px',
		borderBottom: '1px solid black',
		fontSize: '11px',
		textAlign: 'center'
	},
	section_patient_info_element: {
		// display: 'flex'
		flexDirection: 'row',
		alignItems: 'flex-end'
	},
	section_patient_info_signature_element: {
		// display: 'flex'
		flexDirection: 'row',
		alignItems: 'flex-end'
	},
	section_patient_details: {
		width: '60%',
		marginBottom: '15px'
	},
	section_patient_details_element: {
		width: '100%',
		// display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom: '10px'
	},
	patient_details: {
		fontSize: '13px'
	},
	patient_details_underlined: {
		// display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-end',
		textAlign: 'right',
		width: '100px',
		borderBottom: '1px solid black',
		fontSize: '13px'
	},
	section_patient_text_details: {
		fontSize: '12px',
		marginBottom: '5px',
		width: '100%'
	},
	section_patient_text: {
		width: '95%',
		marginBottom: '10px'
	},
	section_patient_signature: {
		width: '95%',
		// display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom: '25px'
	},
	section_dolphin: {
		width: '95%',
		backgroundColor: 'gray',
		border: '1px solid black',
		marginBottom: '15px'
	},
	section_dolphin_title: {
		width: '100%',
		padding: '0 10px 5px 10px',
		// display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		fontSize: '8px'
	},
	section_dolphin_details: {
		// display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	section_dolphin_details_element: {
		width: '25%',
		// display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	section_dolphin_title_text: {
		fontSize: '10px'
	},
	section_dolphin_details_element_title: {
		fontSize: '10px'
	},
	section_dolphin_details_element_row_text: {
		fontSize: '10px'
	},
	section_dolphin_details_element_row: {
		// display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%'
	},
	section_footer_title: {
		// width: '100%',
		fontSize: '11px',
		flexDirection: 'row',
		justifyContent: 'center',
		textAlign: 'center',
		alignItems: 'flex-end',
		marginBottom: '5px'
	},
	signature: {
		marginBottom: '10px',
		width: '95px',
		height: '30px'
	}
});

export const monthlyPaymentDownloadStyles = StyleSheet.create({
	body: {
		width: '100%'
		// display: 'flex',
	},
	page: {
		// display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		backgroundColor: '#fff',
		paddingTop: '20px',
		paddingBottom: '20px',
		paddingRight: '10px',
		paddingLeft: '10px'
	},
	monthly_header: {
		width: '100%',
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: '12px',
		marginBottom: '10px'
	},
	monthly_header_text: {
		fontFamily: 'Helvetica-Bold',
		marginBottom: '10px',
		textTransform: 'uppercase'
	},
	patient_text: {
		fontSize: '10px',
		marginRight: '15px'
		// alignItems: 'end',
	},
	section_patient_info: {
		width: '85%',
		marginBottom: '20px',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	signature_info_wrapper: {
		width: '90%',
		marginBottom: '10px',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	ortho_wrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	ortho_text: {
		fontSize: '10px',
		textAlign: 'center'
	},
	patient_text_underlined: {
		// display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		// alignItems: 'center',
		// flexDirection: 'row',
		width: '150px',
		borderBottom: '1px solid black',
		fontSize: '10px',
		textAlign: 'center'
	},
	section_patient_info_element: {
		// display: 'flex'
		flexDirection: 'row',
		alignItems: 'flex-end'
	},
	details_table_row: {
		flexDirection: 'row',
		width: '90%'
	},
	details_table_right_top_cell: {
		padding: '3px',
		width: '50%',
		height: '30px',
		borderTop: '1px solid black',
		borderLeft: '1px solid black',
		borderRight: '1px solid black'
	},
	details_table_left_top_cell: {
		padding: '3px',
		width: '50%',
		height: '30px',
		borderTop: '1px solid black',
		borderRight: '1px solid black'
	},
	details_table_right_bottom_cell: {
		padding: '3px',
		width: '50%',
		height: '33px',
		borderTop: '1px solid black',
		borderBottom: '1px solid black',
		borderLeft: '1px solid black',
		borderRight: '1px solid black'
	},
	details_table_left_bottom_cell: {
		padding: '3px',
		width: '50%',
		height: '33px',
		borderTop: '1px solid black',
		borderBottom: '1px solid black',
		borderRight: '1px solid black'
	},
	details_table_row_title: {
		fontFamily: 'Helvetica-Bold',
		fontSize: '8px',
		fontWeight: 'bold',
		marginBottom: '5px'
	},
	details_table_row_text: {
		fontSize: '8px'
		// fontWeight: 'bold',
	},
	payment_table_top_row: {
		flexDirection: 'row',
		width: '90%',
		marginTop: '15px'
	},
	payment_table_bottom_row: {
		flexDirection: 'row',
		width: '90%',
		marginBottom: '10px'
	},
	payment_table_top_small_cell: {
		fontFamily: 'Helvetica-Bold',
		fontSize: '8px',
		textAlign: 'center',
		width: '16.6%',
		padding: '2px',
		height: '30px',
		alignItems: 'center',
		justifyContent: 'center',
		borderTop: '1px solid black',
		borderBottom: '1px solid black',
		borderLeft: '1px solid black',
		backgroundColor: '#D9D9D9'
	},
	payment_table_top_big_cell: {
		fontFamily: 'Helvetica-Bold',
		fontSize: '8px',
		textAlign: 'center',
		width: '33.3%',
		padding: '2px',
		height: '30px',
		alignItems: 'center',
		justifyContent: 'center',
		borderTop: '1px solid black',
		borderBottom: '1px solid black',
		borderLeft: '1px solid black',
		borderRight: '1px solid black',
		backgroundColor: '#D9D9D9'
	},
	payment_table_bottom_small_cell: {
		// fontFamily: 'Helvetica-Bold',
		fontSize: '8px',
		textAlign: 'center',
		width: '16.6%',
		padding: '2px',
		height: '35px',
		alignItems: 'center',
		justifyContent: 'center',
		// borderTop: '1px solid black',
		borderBottom: '1px solid black',
		borderLeft: '1px solid black'
		// borderRight: '1px solid black',
		// backgroundColor: '#D9D9D9'
	},
	payment_table_bottom_big_cell: {
		fontFamily: 'Helvetica-Bold',
		fontSize: '8px',
		textAlign: 'center',
		width: '33.3%',
		// padding: '2px',
		height: '35px',
		alignItems: 'center',
		justifyContent: 'center',
		// borderTop: '1px solid black',
		borderBottom: '1px solid black',
		borderLeft: '1px solid black',
		borderRight: '1px solid black'
		// backgroundColor: '#D9D9D9'
	},
	payment_table_bottom_big_cell_block: {
		flexDirection: 'row',
		width: '100%'
	},
	payment_table_bottom_big_mini_cell: {
		fontFamily: 'Helvetica',
		fontSize: '8px',
		textAlign: 'center',
		width: '33.3%',
		padding: '2px',
		height: '17.5px',
		alignItems: 'center',
		justifyContent: 'center',
		// borderTop: '1px solid black',
		borderBottom: '1px solid black',
		// borderLeft: '1px solid black',
		borderRight: '1px solid black',
		backgroundColor: '#D9D9D9'
	},
	payment_table_bottom_big_mini_cell_last: {
		fontFamily: 'Helvetica',
		fontSize: '8px',
		textAlign: 'center',
		width: '33.3%',
		padding: '2px',
		height: '17.5px',
		alignItems: 'center',
		justifyContent: 'center',
		// borderTop: '1px solid black',
		borderBottom: '1px solid black',
		// borderLeft: '1px solid black',
		// borderRight: '1px solid black',
		backgroundColor: '#D9D9D9'
	},
	payment_table_bottom_big_mini_cell_content_block: {
		fontFamily: 'Helvetica',
		flexDirection: 'row',
		width: '100%',
		height: '17.5px'
	},
	payment_table_bottom_big_mini_cell_content: {
		fontSize: '8px',
		textAlign: 'center',
		width: '33.3%',
		padding: '2px',
		height: '17.5px',
		alignItems: 'center',
		justifyContent: 'center',
		borderRight: '1px solid black'
	},
	payment_table_bottom_big_mini_cell_content_last: {
		fontSize: '8px',
		textAlign: 'center',
		width: '33.3%',
		padding: '2px',
		height: '17.5px',
		alignItems: 'center',
		justifyContent: 'center'
	},
	primary_account: {
		width: '90%',
		justifyContent: 'flex-start'
	},
	primary_account_title: {
		fontFamily: 'Helvetica-Bold',
		fontSize: '8px',
		marginBottom: '5px'
	},
	primary_account_table: {
		width: '90%'
	},
	primary_account_table_title: {
		fontFamily: 'Helvetica-Bold',
		width: '100%',
		height: '15px',
		fontSize: '8px',
		textAlign: 'center',
		alignItems: 'center',
		justifyContent: 'center',
		borderTop: '1px solid black',
		borderLeft: '1px solid black',
		borderRight: '1px solid black',
		backgroundColor: '#D9D9D9'
	},
	primary_account_table_bank_info: {
		width: '100%',
		height: '80px',
		alignItems: 'center',
		borderTop: '1px solid black',
		borderLeft: '1px solid black',
		borderRight: '1px solid black'
	},
	primary_account_table_card_info: {
		width: '100%',
		height: '80px',
		alignItems: 'center',
		// justifyContent: 'center',
		borderTop: '1px solid black',
		borderLeft: '1px solid black',
		borderRight: '1px solid black',
		borderBottom: '1px solid black'
	},
	primary_account_table_bank_info_block: {
		width: '50%',
		padding: '10px 0',
		justifyContent: 'space-between'
	},
	primary_account_table_bank_info_block_checkbox: {
		flexDirection: 'row',
		alignItems: 'center'
	},
	primary_account_table_bank_info_checking: {
		flexDirection: 'row',
		alignItems: 'center',
		marginRight: '15px'
	},
	primary_account_table_bank_info_savings: {
		flexDirection: 'row',
		alignItems: 'center'
	},
	primary_account_table_bank_info_checkbox: {
		width: '8px',
		height: '8px',
		marginRight: '5px',
		border: '1px solid black',
		alignItems: 'center',
		justifyContent: 'center'
	},
	checkImage: {
		width: 6,
		height: 8,
	},
	primary_account_table_bank_info_text: {
		fontSize: '8px'
	},
	primary_account_table_bank_info_block_name: {
		marginTop: '10px'
	},
	primary_account_table_bank_info_block_name_underline: {
		width: '100%',
		borderBottom: '1px',
		fontSize: '8px',
		height: '10px'
	},
	primary_account_table_bank_info_block_name_text: {
		fontSize: '7px'
	},
	primary_account_table_bank_info_block_account: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginTop: '5px'
		// height: '15px'
	},
	primary_account_table_bank_info_block_account_right: {
		width: '45%'
	},
	primary_account_table_bank_info_block_account_left: {
		width: '45%'
	},
	primary_account_table_bank_info_block_account_underline: {
		width: '100%',
		borderBottom: '1px',
		fontSize: '8px',
		// height: '10px'
	},
	primary_account_table_bank_info_block_account_text: {
		fontSize: '7px'
	},
	primary_account_table_card_info_block: {
		width: '50%',
		padding: '15px 0',
		justifyContent: 'space-between',
		flexDirection: 'column'
	},
	primary_account_table_card_top: {
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	primary_account_table_card_top_text: {
		fontSize: '8px'
	},
	primary_account_table_card_top_card_text: {
		fontSize: '8px',
		marginRight: '15px'
	},
	primary_account_table_card_top_underline: {
		fontSize: '8px',
		width: '120px',
		borderBottom: '1px solid black'
	},
	primary_account_table_card_center_text: {
		fontSize: '8px',
		marginRight: '15px'
	},
	primary_account_table_card_center_underline: {
		fontSize: '8px',
		width: '100%',
		borderBottom: '1px solid black'
	},
	primary_account_table_card_center: {
		marginTop: '8px',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	primary_account_table_card_bottom: {
		marginTop: '8px',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	info: {
		width: '90%',
		marginBottom: '10px'	
	},
	info_title: {
		fontFamily: 'Helvetica-Bold',
		fontSize: '10px',
		textAlign: 'center',
		marginTop: '15px',
		marginBottom: '10px'
	},
	info_details: {
		// lineHeight: '2px'
		fontSize: '6px',
		marginBottom: '10px'
	},
	info_details_bold: {
		fontFamily: 'Helvetica-Bold',
		fontSize: '6px',
	},
	signature: {
		flexDirection: 'row',
		width: '90%',
		textAlign: 'left',
		marginBottom: '10px',
		height: '40px',
		alignItems: 'flex-end'
	},
	signature_img: {
		marginBottom: '10px',
		width: '95px',
		height: '30px'
	},
	signature_text: {
		fontSize: '8px',
		marginRight: '10px'
	},
	signature_underline: {
		width: '115px',
		height: '40px',
		borderBottom: '1px solid black',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: '20px'
	},
	date: {
		flexDirection: 'row',
		width: '90%',
		textAlign: 'left',
		marginBottom: '10px',
		height: '15px',
		alignItems: 'flex-end'
	},
	date_underline: {
		fontSize: '8px',
		width: '55px',
		borderBottom: '1px solid black',
		textAlign: 'center'
	},
	oid_table: {
		width:'90%'
	},
	oid_table_title: {
		width: '50%',
		fontFamily: 'Helvetica-Bold',
		height: '15px',
		fontSize: '7px',
		textAlign: 'center',
		alignItems: 'center',
		justifyContent: 'center',
		borderTop: '1px solid black',
		borderLeft: '1px solid black',
		borderRight: '1px solid black',
		borderBottom: '1px solid black',
		backgroundColor: '#D9D9D9'
	},
	oid_table_content: {
		width: '50%',
		flexDirection: 'row',
	},
	oid_table_content_right: {
		width: '50%',
		fontSize: '7px',
		textAlign: 'center',
		justifyContent: 'center',
		alignItems: 'center',
		height: '15px',
		// borderLeft: '1px solid black',
		borderRight: '1px solid black',
		borderBottom: '1px solid black',
	},
	oid_table_content_left: {
		width: '50%',
		fontSize: '7px',
		textAlign: 'center',
		justifyContent: 'center',
		alignItems: 'center',
		height: '15px',
		borderLeft: '1px solid black',
		borderRight: '1px solid black',
		borderBottom: '1px solid black',
	},
	ortho_number: {
		width: '30%',
	},
	ortho_number_title: {
		width: '100%',
		fontFamily: 'Helvetica-Bold',
		height: '15px',
		fontSize: '7px',
		textAlign: 'center',
		alignItems: 'center',
		justifyContent: 'center',
		borderTop: '1px solid black',
		borderLeft: '1px solid black',
		borderRight: '1px solid black',
		borderBottom: '1px solid black',
		backgroundColor: '#D9D9D9'
	},
	ortho_number_content: {
		height: '15px',
		fontSize: '7px',
		textAlign: 'center',
		alignItems: 'center',
		justifyContent: 'center',
		borderLeft: '1px solid black',
		borderRight: '1px solid black',
		borderBottom: '1px solid black',
	},
	address: {
		marginTop: '10px',
		flexDirection: 'row',
		width: '90%',
		justifyContent: 'space-between',
		fontSize: '7px',
	},
	contact: {
		marginTop: '5px',
		flexDirection: 'row',
		width: '90%',
		fontSize: '7px'
	}
});

export const styles = StyleSheet.create({
	body: {
		width: '75%'
		// display: 'flex',
	},
	page: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		backgroundColor: '#fff',
		paddingTop: '20px',
		paddingBottom: '20px',
		paddingRight: '10px',
		paddingLeft: '10px'
	},
	section_header: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '70%',
		alignItems: 'center'
	},
	logo: {
		display: 'flex',
		width: '150px',
		height: '150px',
		marginBottom: '50px',
		borderRadius: '15px'
	},
	header: {
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: '28px',
		marginBottom: '50px'
	},
	patient_text: {
		fontSize: '20px',
		marginRight: '15px'
	},
	section_patient_info: {
		width: '85%',
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: '50px'
	},
	patient_text_underlined: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		width: '280px',
		borderBottom: '1px solid black',
		fontSize: '20px'
	},
	section_patient_info_element: {
		display: 'flex',
		alignItems: 'end'
	},
	section_patient_details: {
		width: '60%',
		marginBottom: '30px'
	},
	section_patient_details_element: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: '10px'
	},
	patient_details: {
		fontSize: '23px'
	},
	patient_details_underlined: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-end',
		width: '200px',
		borderBottom: '1px solid black',
		fontSize: '23px'
	},
	section_patient_text_details: {
		fontSize: '22px',
		marginBottom: '20px',
		width: '100%'
	},
	section_patient_text: {
		width: '95%'
		// marginBottom: '30px'
	},
	section_patient_signature: {
		width: '95%',
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: '30px'
	},
	section_dolphin: {
		width: '95%',
		backgroundColor: 'gray',
		border: '1px solid black',
		marginBottom: '50px'
	},
	section_dolphin_title: {
		width: '100%',
		padding: '0 10px 5px 10px',
		display: 'flex',
		justifyContent: 'space-between',
		fontSize: '12px'
	},
	section_dolphin_details: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	section_dolphin_details_element: {
		width: '20%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	section_dolphin_details_element_row: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%'
	},
	section_footer_title: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		textAlign: 'center'
	}
});
