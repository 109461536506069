import React from 'react';
import './PatientCard.scss';

import Icon from '../../Icon';
import { useParams } from 'react-router-dom';

const PatientCard = ({ patient, isActive }) => {
	const { id } = useParams();
	const date = patient.firstName === 'New' ? new Date(Date.now()) : new Date(patient.createdAt)
	const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

	const day = date.getDate();
	const monthIndex = date.getMonth();
	const year = date.getFullYear();

	const formattedMonth = monthNames[monthIndex];

	const formattedDate = day + ' ' + formattedMonth + ' ' + year;

	const statuses = [
		{ id: 0, name: 'New' },
		{ id: 1, name: 'Presented' },
		{ id: 2, name: 'Accepted' },
		{ id: 3, name: 'Signed' },
		{ id: 4, name: 'Paid' },
		{ id: 5, name: 'Payment Info' },
		{ id: 6, name: 'Completed' }
	];

	return (
		<>
			<div className="patient-card-info">
				<div
					className="patient-card-avatar"
					style={{ backgroundColor: `${patient.avatarBackground}`, opacity: 0.5 }}></div>
				<div className="patient-card-details">
					<h5>{patient.firstName + ' ' + patient.lastName}</h5>
					<p className="patient-card-number">{patient.patientNumber}</p>
					<p className="patient-card-status-title">Status</p>
					<b className="patient-card-status">{statuses.find(s => s.id === patient?.status)?.name}</b>
				</div>
				<Icon
					name={`${isActive ? 'arrow-right' : 'arrow-left'}`}
					styles={{ fill: `${patient?.isEdit ? '#F15757' : '#26A4ED'}` }}
				/>
			</div>
			<p className="patient-card-date">{formattedDate}</p>
		</>
	);
};

export default PatientCard;
