import React, { useEffect, useState } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
  PDFRenderer,
  PDFViewer,
  Font,
} from '@react-pdf/renderer';
import {
  calculateDates,
  calculateWithdrawalDate,
  formatMiniPhoneNumber,
  getCardType,
  getDay,
  getMonth,
  getYear,
} from '../../utils/utils';

const MonthlyPaymentDownload = ({ styles, presentationData, signature }) => {
  console.log(presentationData);
  const date = presentationData?.orthoDate
    ? new Date(presentationData?.orthoDate)
    : new Date();

  let today;

  if (presentationData && presentationData?.orthoDate) {
    today = calculateDates(
      presentationData?.orthoDate !== null &&
        presentationData?.orthoDate !== 'null'
        ? new Date(presentationData?.orthoDate)
        : new Date()
    ).withdrawalBeginDate;
  } else {
    today = new Date();
  }

  useEffect(() => {
    console.log(today);
  }, [today]);

  return (
    <Document creator={'123'} producer={'123'} style={styles.body}>
      <Page size="LETTER" style={styles.page}>
        <View style={styles.monthly_header}>
          <Text style={styles.monthly_header_text}>Orthobanc, LCC</Text>
          <Text style={styles.monthly_header_text}>Recurring Payment Plan</Text>
        </View>
        <View style={styles.section_patient_info}>
          <View
            style={[
              styles.section_patient_info_element,
              { marginRight: '10px' },
            ]}
          >
            <Text style={styles.patient_text}>Orthodontist</Text>
            <Text style={styles.patient_text_underlined}>
              {presentationData?.organization?.name}
            </Text>
          </View>
          <View style={styles.section_patient_info_element}>
            <Text style={styles.patient_text}>Account #</Text>
            <Text style={styles.patient_text_underlined}>
              {presentationData?.patientNumber}
            </Text>
          </View>
        </View>
        <View style={styles.details_table_row}>
          <View style={styles.details_table_right_top_cell}>
            <Text style={styles.details_table_row_title}>
              Responsible Name:
            </Text>
            <Text style={styles.details_table_row_text}>
              {presentationData
                ? `${presentationData?.orthoFirstName} ${presentationData?.orthoLastName}`
                : 'John Smith'}
            </Text>
          </View>
          <View style={styles.details_table_left_top_cell}>
            <Text style={styles.details_table_row_title}>Patient Name:</Text>
            <Text style={styles.details_table_row_text}>
              {presentationData
                ? `${presentationData?.orthoFirstName} ${presentationData?.orthoLastName}`
                : 'John Smith'}
            </Text>
          </View>
        </View>
        <View style={styles.details_table_row}>
          <View style={styles.details_table_right_top_cell}>
            <Text style={styles.details_table_row_title}>
              Responsible Adress:
            </Text>
            <Text style={styles.details_table_row_text}>
              {presentationData?.address
                ? `${presentationData?.address}`
                : 'Some street name'}
            </Text>
          </View>
          <View style={styles.details_table_left_top_cell}>
            <Text style={styles.details_table_row_title}>Email:</Text>
            <Text style={styles.details_table_row_text}>
              {presentationData?.orthoEmail
                ? `${presentationData?.orthoEmail}`
                : 'Responsible Email'}
            </Text>
          </View>
        </View>
        <View style={styles.details_table_row}>
          <View style={styles.details_table_right_top_cell}>
            <Text style={styles.details_table_row_title}>
              City, State, Zip:
            </Text>
            <Text style={styles.details_table_row_text}>
              {presentationData?.state
                ? `${presentationData?.city} ${presentationData?.state} ${presentationData?.zipCode}`
                : 'New York, New York, 234421'}
            </Text>
          </View>
          <View style={styles.details_table_left_top_cell}>
            <Text style={styles.details_table_row_title}></Text>
            <Text style={styles.details_table_row_text}></Text>
          </View>
        </View>
        <View style={styles.details_table_row}>
          <View style={styles.details_table_right_bottom_cell}>
            <Text style={styles.details_table_row_title}>Home #:</Text>
            <Text style={styles.details_table_row_text}>
              {presentationData?.phoneNumber
                ? `${presentationData?.phoneNumber}`
                : ''}
            </Text>
          </View>
          <View style={styles.details_table_left_bottom_cell}>
            <Text style={styles.details_table_row_title}></Text>
            <Text style={styles.details_table_row_text}></Text>
          </View>
        </View>
        <View style={styles.payment_table_top_row}>
          <View style={styles.payment_table_top_small_cell}>
            <Text style={styles.payment_table_title}>
              Amount of Total Withdrawal
            </Text>
          </View>
          <View style={styles.payment_table_top_small_cell}>
            <Text style={styles.payment_table_title}>
              Monthly Payment Amount
            </Text>
          </View>
          <View style={styles.payment_table_top_small_cell}>
            <Text style={styles.payment_table_title}>Final Payment Amount</Text>
          </View>
          <View style={styles.payment_table_top_small_cell}>
            <Text style={styles.payment_table_title}>
              Total Number of Monthly Withdrawals
            </Text>
          </View>
          <View style={styles.payment_table_top_big_cell}>
            <Text style={styles.payment_table_title}>
              Withdrawal Begin Date
            </Text>
          </View>
        </View>
        <View style={styles.payment_table_bottom_row}>
          <View style={styles.payment_table_bottom_small_cell}>
            <Text style={styles.payment_table_title}>
              $
              {presentationData?.calculations?.contract?.totalOfPayments_calc?.toFixed(
                2
              )}
            </Text>
          </View>
          <View style={styles.payment_table_bottom_small_cell}>
            <Text style={styles.payment_table_title}>
              $
              {presentationData?.calculations?.payments?.bpMonthlyPayment_calc?.toFixed(
                2
              )}
            </Text>
          </View>
          <View style={styles.payment_table_bottom_small_cell}>
            <Text style={styles.payment_table_title}>
              $
              {presentationData?.calculations?.contract?.finalPayment_calc?.toFixed(
                2
              )}
            </Text>
          </View>
          <View style={styles.payment_table_bottom_small_cell}>
            <Text style={styles.payment_table_title}>
              {presentationData?.calculations?.payments?.bpSelectedMonths_calc}
            </Text>
          </View>
          <View style={styles.payment_table_bottom_big_cell}>
            <View style={styles.payment_table_bottom_big_cell_block}>
              <View style={styles.payment_table_bottom_big_mini_cell}>
                <Text style={styles.payment_table_bottom_big_mini_cell_text}>
                  Month
                </Text>
              </View>
              <View style={styles.payment_table_bottom_big_mini_cell}>
                <Text style={styles.payment_table_bottom_big_mini_cell_text}>
                  Day
                </Text>
              </View>
              <View style={styles.payment_table_bottom_big_mini_cell_last}>
                <Text style={styles.payment_table_bottom_big_mini_cell_text}>
                  Year
                </Text>
              </View>
            </View>
            <View
              style={styles.payment_table_bottom_big_mini_cell_content_block}
            >
              <View style={styles.payment_table_bottom_big_mini_cell_content}>
                <Text style={styles.payment_table_bottom_big_mini_cell_text}>
                  {getMonth(today)}
                </Text>
              </View>
              <View style={styles.payment_table_bottom_big_mini_cell_content}>
                <Text style={styles.payment_table_bottom_big_mini_cell_text}>
                  {getDay(today)}
                </Text>
              </View>
              <View
                style={styles.payment_table_bottom_big_mini_cell_content_last}
              >
                <Text style={styles.payment_table_bottom_big_mini_cell_text}>
                  {getYear(today)}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.primary_account}>
          <Text style={styles.primary_account_title}>
            Please select the primary account OrthoBanc is to debit:
          </Text>
        </View>
        <View style={styles.primary_account_table}>
          <View style={styles.primary_account_table_title}>
            <Text>Primary Account</Text>
          </View>
          <View style={styles.primary_account_table_bank_info}>
            <View style={styles.primary_account_table_bank_info_block}>
              <View
                style={styles.primary_account_table_bank_info_block_checkbox}
              >
                <View style={styles.primary_account_table_bank_info_checking}>
                  <View style={styles.primary_account_table_bank_info_checkbox}>
                    {presentationData?.checking ? (
                      <Image
                        src={require('./check.png')}
                        style={styles.checkImage}
                      />
                    ) : (
                      ''
                    )}
                  </View>
                  <Text style={styles.primary_account_table_bank_info_text}>
                    Checking
                  </Text>
                </View>
                <View style={styles.primary_account_table_bank_info_savings}>
                  <View style={styles.primary_account_table_bank_info_checkbox}>
                    {presentationData?.saving ? (
                      <Image
                        src={require('./check.png')}
                        style={styles.checkImage}
                      />
                    ) : (
                      ''
                    )}
                  </View>
                  <Text style={styles.primary_account_table_bank_info_text}>
                    Savings
                  </Text>
                </View>
              </View>
              <View style={styles.primary_account_table_bank_info_block_name}>
                <Text
                  style={
                    styles.primary_account_table_bank_info_block_name_underline
                  }
                >
                  {presentationData?.accountNumber === ''
                    ? ''
                    : presentationData?.orthoFirstName +
                      ' ' +
                      presentationData?.orthoLastName}
                </Text>
                <Text
                  style={styles.primary_account_table_bank_info_block_name_text}
                >
                  Name(s) as it appears on your account
                </Text>
              </View>
              <View
                style={styles.primary_account_table_bank_info_block_account}
              >
                <View
                  style={
                    styles.primary_account_table_bank_info_block_account_right
                  }
                >
                  <Text
                    style={
                      styles.primary_account_table_bank_info_block_account_underline
                    }
                  >
                    {presentationData?.accountNumber === ''
                      ? ''
                      : presentationData?.accountNumber}
                  </Text>
                  <Text
                    style={
                      styles.primary_account_table_bank_info_block_account_text
                    }
                  >
                    Bank Account #
                  </Text>
                </View>
                <View
                  style={
                    styles.primary_account_table_bank_info_block_account_left
                  }
                >
                  <Text
                    style={
                      styles.primary_account_table_bank_info_block_account_underline
                    }
                  >
                    {presentationData?.routingNumber === ''
                      ? ''
                      : presentationData?.routingNumber}
                  </Text>
                  <Text
                    style={
                      styles.primary_account_table_bank_info_block_account_text
                    }
                  >
                    Routing #
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.primary_account_table_card_info}>
            <View style={styles.primary_account_table_card_info_block}>
              <View style={styles.primary_account_table_card_top}>
                <View style={styles.primary_account_table_bank_info_checkbox}>
                  {presentationData?.cardNumber ? (
                    <Image
                      src={require('./check.png')}
                      style={styles.checkImage}
                    />
                  ) : (
                    ''
                  )}
                </View>
                <Text style={styles.primary_account_table_card_top_card_text}>
                  Credit Card
                </Text>
                <Text style={styles.primary_account_table_card_top_text}>
                  Card Type
                </Text>
                <Text style={styles.primary_account_table_card_top_underline}>
                  {presentationData?.cardNumber === ''
                    ? ''
                    : getCardType(presentationData?.cardNumber)}
                </Text>
              </View>
              <View style={styles.primary_account_table_card_center}>
                <Text style={styles.primary_account_table_card_center_text}>
                  Credit Card *
                </Text>
                <Text
                  style={styles.primary_account_table_card_center_underline}
                >
                  {presentationData?.cardNumber === ''
                    ? ''
                    : presentationData?.cardNumber}
                </Text>
              </View>
              <View style={styles.primary_account_table_card_bottom}>
                <Text style={styles.primary_account_table_card_center_text}>
                  Expiration Date:
                </Text>
                <Text
                  style={styles.primary_account_table_card_center_underline}
                >
                  {presentationData?.expDate === ''
                    ? ''
                    : presentationData?.expDate}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.info}>
          <Text style={styles.info_title}>
            ORTHOBANC, LLC EFT AUTHORIZATION
          </Text>
          <Text style={styles.info_details}>
            I hereby authorize{' '}
            <Text style={styles.info_details_bold}>OrthoBanc</Text>, LLC
            (hereinafter <Text style={styles.info_details_bold}>OrthoBanc</Text>
            ), on behalf of the Orthodontist, to initiate debit entries to the
            account(s) indicated above (of which I am an authorized signer) via
            electronic funds transfer (EFT). I understand that beginning on the
            date listed above,
            <Text style={styles.info_details_bold}>OrthoBanc</Text> will begin
            withdrawals from my bank or credit/debit card account. Such
            withdrawals will continue each month until the entire balance,
            provided to <Text style={styles.info_details_bold}>OrthoBanc</Text>{' '}
            by the Orthodontist, is paid in full. I understand should my
            financial institution debit my account before the effective date
            supplied by <Text style={styles.info_details_bold}>OrthoBanc</Text>{' '}
            to the financial institution in its processing file, that this is
            not a processing error on behalf of{' '}
            <Text style={styles.info_details_bold}>OrthoBanc</Text>. I
            understand <Text style={styles.info_details_bold}>OrthoBanc</Text>{' '}
            is debiting funds from my account for payment to the Orthodontist,
            for professional services rendered, and the name
            <Text style={styles.info_details_bold}>OrthoBanc</Text> may/will
            appear on my monthly statement. I understand my final payment may be
            slightly more/less than the Monthly Payment Amount listed above, but
            will not exceed the balance of the account at{' '}
            <Text style={styles.info_details_bold}>OrthoBanc</Text> as of the
            date of the payment.
          </Text>
          <Text style={styles.info_details}>
            I further agree that should{' '}
            <Text style={styles.info_details_bold}>OrthoBanc</Text> be notified
            that funds are not available in my bank account (NSF, closed
            account, etc.) or that a charge to my credit/debit card is denied, a
            $25 failed payment fee will be charged by{' '}
            <Text style={styles.info_details_bold}>OrthoBanc</Text>. I agree
            that if funds are not available from the account I choose as
            primary, <Text style={styles.info_details_bold}>OrthoBanc</Text> can
            attempt to secure funds from my secondary account. If no secondary
            account is provided,{' '}
            <Text style={styles.info_details_bold}>OrthoBanc</Text> can re-draft
            my primary account. I understand if I choose to discontinue this
            method of payment I must notify{' '}
            <Text style={styles.info_details_bold}>OrthoBanc</Text> a minimum of
            4 business days prior to my scheduled debit date. I also authorize{' '}
            <Text style={styles.info_details_bold}>OrthoBanc</Text> to contact
            me at any of the telephone numbers listed above regarding this
            account, including through use of an autodialer, or text or
            prerecorded messaging. I agree to notify{' '}
            <Text style={styles.info_details_bold}>OrthoBanc</Text> immediately
            if my cell phone numbers change.
          </Text>
        </View>
        <View style={styles.signature_info_wrapper}>
          <View style={styles.signature_wrapper}>
            <View style={styles.signature}>
              <Text style={styles.signature_text}>Signature:</Text>
              <Text style={styles.signature_underline}>
                {presentationData?.sign !== null &&
                presentationData?.sign !== undefined ? (
                  <Image
                    src={presentationData?.sign}
                    style={styles.signature_img}
                    alt="signature"
                  />
                ) : (
                  'Empty'
                )}
              </Text>
              {/* <View style={styles.date}> */}
              <Text style={styles.signature_text}>Date:</Text>
              <Text style={styles.date_underline}>
                {presentationData?.orthoSignDate
                  ? `${getMonth(
                      new Date(presentationData?.orthoSignDate)
                    )}/${getDay(
                      new Date(presentationData?.orthoSignDate)
                    )}/${getYear(new Date(presentationData?.orthoSignDate))}`
                  : `${getMonth(today)}/${getDay(today)}/${getYear(today)}`}
              </Text>
              {/* </View> */}
            </View>
          </View>
          {/* <View style={styles.ortho_wrapper}>
            <Text style={styles.ortho_text}>123</Text>
          </View> */}
          <View style={styles.ortho_number}>
            <View style={styles.ortho_number_title}>
              <Text style={styles.ortho_number_title_text}>
                OrthoBanc Number
              </Text>
            </View>
            <View style={styles.ortho_number_content}>
              <Text style={styles.ortho_number_content_text}>
                {presentationData?.orthoBancNumber}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.oid_table}>
          <View style={styles.oid_table_title}>
            <Text style={styles.oid_table_title_text}>
              For Provider use Only
            </Text>
          </View>
          <View style={styles.oid_table_content}>
            <View style={styles.oid_table_content_left}>
              <Text style={styles.oid_table_content_left_text}>
                OID/PID Number
              </Text>
            </View>
            <View style={styles.oid_table_content_right}>
              <Text style={styles.oid_table_content_right_text}>
                of00001439/op00001482
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.address}>
          <Text style={styles.address_text}>
            2835 Northpoint Blvd, Hixson TN 37343
          </Text>
          <Text style={styles.address_text}>RPP - Revision Date Mar - 17</Text>
        </View>
        <View style={styles.contact}>
          <Text style={styles.address_text}>
            P: 800-636-6600, Option 1 F: (888) 758-0586 www.orthobanc.com
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default MonthlyPaymentDownload;
