import React, { useEffect, useRef, useState } from 'react';
import s from './monthlyPaymentContract.module.scss';
import Loader from '../Loader/Loader';
import { getCardType, getDay, getMonth, getTodayDate, getYear } from '../../utils/utils';
import toast, { Toaster } from 'react-hot-toast';
import MonthlyPaymentDownload from './MonthlyPaymentDownload';
import { pdf, PDFDownloadLink } from '@react-pdf/renderer';
import { useDispatch } from 'react-redux';
import SignatureCanvas from 'react-signature-canvas';
import Popup from 'reactjs-popup';
import { saveAs } from 'file-saver';
import { monthlyPaymentDownloadStyles } from './styles';
import { getSignedMonthlyContract, signMonthlyContract } from '../../store/reducers/PaymentReducer';
import { useNavigate, useParams } from 'react-router-dom';

const MonthlyPaymentDocument = ({ formData, presentationData }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [signature, setSignature] = useState(null);
	const sigCanvas = useRef({});
	const { id, date, treatmentId } = useParams();

	const clear = () => sigCanvas.current.clear();

	const handleSave = async url => {
		console.log(url);
		const blob = await pdf(
			<MonthlyPaymentDownload
				styles={monthlyPaymentDownloadStyles}
				formData={formData}
				presentationData={presentationData}
				signature={url}
			/>
		).toBlob();
		console.log(blob);
		// saveAs(blob, 'document.pdf');
		dispatch(signMonthlyContract({ file: blob, id: id })).then(() => {
			setTimeout(() => {
				navigate(`/patient-profile/${id}/${date}/${treatmentId}`);
			}, 1000);
		});
	};

	const save = () => {
		const signatureURL = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
		setSignature(signatureURL);

		handleSave(signatureURL);
	};

	useEffect(() => {
		if (presentationData?.orthoBancFormUrl && presentationData?.status === 6) {
			navigate(`/patient-profile/${id}/${date}/${treatmentId}`);
		}
	}, [presentationData]);

	if (!formData || !presentationData) {
		return (
			<div className={s.wrapper}>
				<div className={s.contract_wrapper}>
					<Loader />
				</div>
			</div>
		);
	}

	return (
		<>
			<Toaster position="top-right" />
			<div className={s.wrapper}>
				<div className={s.contract_wrapper}>
					<h3 className={s.title}>ORTHOBANC, LLC</h3>
					<h3 className={s.title}>RECURRING PAYMENT PLAN</h3>
					<div className={s.orthodontist_wrapper}>
						<div className={s.orthodontist_left}>
							<p className={s.orthodontist_left_name}>Orthodontist</p>
							<p className={s.orthodontist_left_underline}>{presentationData.organization.name}</p>
						</div>
						<div className={s.orthodontist_right}>
							<p className={s.orthodontist_right_name}>Account #</p>
							<p className={s.orthodontist_right_underline}>{presentationData.patientNumber}</p>
						</div>
					</div>
					<div className={s.details_table}>
						<div className={s.details_top_row}>
							<div className={s.details_top_right_row_content}>
								<p className={s.details_row_content_title}>Responsible Name:</p>
								<p className={s.details_row_content_text}>
									{formData ? `${formData.firstName} ${formData.lastName}` : 'John Smith'}
								</p>
							</div>
							<div className={s.details_top_left_row_content}>
								<p className={s.details_row_content_title}>Patient Name:</p>
								<p className={s.details_row_content_text}>
									{formData ? `${formData.firstName} ${formData.lastName}` : 'John Smith'}
								</p>
							</div>
						</div>
						<div className={s.details_top_row}>
							<div className={s.details_top_right_row_content}>
								<p className={s.details_row_content_title}>Responsible Address:</p>
								<p className={s.details_row_content_text}>
									{formData.streetAddress ? `${formData.streetAddress}` : 'Some street name'}
								</p>
							</div>
							<div className={s.details_top_left_row_content}>
								<p className={s.details_row_content_title}>Email:</p>
								<p className={s.details_row_content_text}>
									{formData.email ? `${formData.email}` : 'Responsible Email'}
								</p>
							</div>
						</div>
						<div className={s.details_top_row}>
							<div className={s.details_top_right_row_content}>
								<p className={s.details_row_content_title}>City, State, Zip:</p>
								<p className={s.details_row_content_text}>
									{formData.state
										? `${formData.city} ${formData.state} ${formData.zipCode}`
										: 'New York, New York, 234421'}
								</p>
							</div>
							<div className={s.details_top_left_row_content}></div>
						</div>
						<div className={s.details_top_row}>
							<div className={s.details_bottom_right_row_content}>
								<p className={s.details_row_content_title}>Home #:</p>
								<p className={s.details_row_content_text}>
									{formData.phoneNumber ? `${formData.phoneNumber}` : ''}
								</p>
							</div>
							<div className={s.details_bottom_left_row_content}></div>
						</div>
					</div>
					<div className={s.payment_table}>
						<div className={s.payment_table_row}>
							<div className={s.payment_table_row_min_cell}>
								<p className={s.payment_table_row_title}>Amount of Total Withdrawal</p>
							</div>
							<div className={s.payment_table_row_min_cell}>
								<p className={s.payment_table_row_title}>Monthly Payment Amount</p>
							</div>
							<div className={s.payment_table_row_min_cell}>
								<p className={s.payment_table_row_title}>Final Payment Amount</p>
							</div>
							<div className={s.payment_table_row_min_cell}>
								<p className={s.payment_table_row_title}>Total Number of Monthly Withdrawls</p>
							</div>
							<div className={s.payment_table_row_big_cell}>
								<p className={s.payment_table_row_title}>Withdrawl Begin Date</p>
							</div>
						</div>
						<div className={s.payment_table_row}>
							<div className={s.payment_table_bottom_row_min_cell}>
								<p className={s.payment_table_row_title}>
									${presentationData.calculations.contract.totalOfPayments_calc.toFixed(2)}
								</p>
							</div>
							<div className={s.payment_table_bottom_row_min_cell}>
								<p className={s.payment_table_row_title}>
									${presentationData.calculations.payments.bpMonthlyPayment_calc.toFixed(2)}
								</p>
							</div>
							<div className={s.payment_table_bottom_row_min_cell}>
								<p className={s.payment_table_row_title}>
									${presentationData.calculations.contract.finalPayment_calc.toFixed(2)}
								</p>
							</div>
							<div className={s.payment_table_bottom_row_min_cell}>
								<p className={s.payment_table_row_title}>
									{presentationData.calculations.payments.bpSelectedMonths_calc}
								</p>
							</div>
							<div className={s.payment_table_bottom_row_big_cell}>
								<div className={s.payment_table_bottom_mini_top_row}>
									<div className={s.payment_table_bottom_mini_top_cell}>
										<p className={s.payment_table_bottom_mini_top_cell_title}>Month</p>
										<p className={s.payment_table_bottom_mini_top_cell_title}>Day</p>
										<p className={s.payment_table_bottom_mini_top_cell_title}>Year</p>
									</div>
								</div>
								<div className={s.payment_table_bottom_mini_bottom_row}>
									<div className={s.payment_table_bottom_mini_bottom_cell}>
										<p className={s.payment_table_bottom_mini_bottom_cell_title}>
											{getMonth(presentationData.calculations.contract.firstPaymentDue_calc)}
										</p>
										<p className={s.payment_table_bottom_mini_bottom_cell_title}>
											{getDay(presentationData.calculations.contract.firstPaymentDue_calc)}
										</p>
										<p className={s.payment_table_bottom_mini_bottom_cell_title}>
											{getYear(presentationData.calculations.contract.firstPaymentDue_calc)}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<p className={s.primary_title}>Please select the primary account OrthoBanc is to debit:</p>
					<div className={s.primary_table}>
						<div className={s.primary_title_row}>
							<p className={s.primary_title_text}>Primary Account</p>
						</div>
						<div className={s.primary_bank_row}>
							<div className={s.primary_bank_block}>
								<div className={s.primary_bank_checkbox_row}>
									<div className={s.primary_bank_checkbox_block}>
										<div className={s.primary_bank_checkbox}>{formData.checking ? '✓' : ''}</div>
										<p className={s.primary_bank_checkbox_text}>Checking</p>
									</div>
									<div className={s.primary_bank_checkbox_block}>
										<div className={s.primary_bank_checkbox}>{formData.saving ? '✓' : ''}</div>
										<p className={s.primary_bank_checkbox_text}>Savings</p>
									</div>
								</div>
								<div className={s.primary_bank_name_row}>
									<p className={s.primary_bank_name_underline}>
										{formData.accountNumber === ''
											? ''
											: formData.firstName + ' ' + formData.lastName}
									</p>
									<p className={s.primary_bank_name_text}>Name(s) as it appears on your account</p>
								</div>
								<div className={s.primary_bank_numbers_row}>
									<div className={s.primary_bank_numbers_block}>
										<p className={s.primary_bank_numbers_underline}>
											{formData.accountNumber === '' ? '' : formData.accountNumber}
										</p>
										<p className={s.primary_bank_numbers_text}>Bank Account #</p>
									</div>
									<div className={s.primary_bank_numbers_block}>
										<p className={s.primary_bank_numbers_underline}>
											{formData.routingNumber === '' ? '' : formData.routingNumber}
										</p>
										<p className={s.primary_bank_numbers_text}>Routing #</p>
									</div>
								</div>
							</div>
						</div>
						<div className={s.primary_card_row}>
							<div className={s.primary_card_block}>
								<div className={s.primary_card_first}>
									<div className={s.primary_card_left}>
										<div className={s.primary_bank_checkbox}>
											{formData.cardNumber === '' ? '' : '✓'}
										</div>
										<p className={s.primary_card_checkbox_text}>Credit Card</p>
									</div>
									<div className={s.primary_card_left}>
										<p className={s.primary_card_text}>Card Type</p>
										<p className={s.primary_card_underline}>
											{formData.cardNumber === '' ? '' : getCardType(formData.cardNumber)}
										</p>
									</div>
								</div>
								<div className={s.primary_card_second}>
									<p className={s.primary_card_second_text}>Credit Card *</p>
									<p className={s.primary_card_second_underline}>
										{formData.cardNumber === '' ? '' : formData.cardNumber}
									</p>
								</div>
								<div className={s.primary_card_third}>
									<p className={s.primary_card_second_text}>Expiration Date:</p>
									<p className={s.primary_card_second_underline}>
										{formData.expirationDate === '' ? '' : formData.expirationDate}
									</p>
								</div>
							</div>
						</div>
					</div>
					<h3 className={s.title}>ORTHOBANC, LLC EFT AUTHORIZATION</h3>
					<p className={s.subtitle}>
						I hereby authorize <b>OrthoBanc</b>, LLC (hereinafter <b>"OrthoBanc"</b>), on behalf of the
						Orthodontist, to initiate debit entries to the account(s) indicated above (of which I am an
						authorized signer) via electronic funds transfer (EFT). I understand that beginning on the date
						listed above, <b>OrthoBanc</b> will begin withdrawals from my bank or credit/debit card account.
						Such withdrawals will continue each month until the entire balance, provided to <b>OrthoBanc</b>{' '}
						by the Orthodontist, is paid in full. I understand should my financial institution debit my
						account before the effective date supplied by <b>OrthoBanc</b> to the financial institution in
						its processing file, that this is not a processing error on behalf of <b>OrthoBanc</b>. I
						understand <b>OrthoBanc</b> is debiting funds from my account for payment to the Orthodontist,
						for professional services rendered, and the name <b>OrthoBanc</b> may/will appear on my monthly
						statement. I understand my final payment may be slightly more/less than the Monthly Payment
						Amount listed above, but will not exceed the balance of the account at <b>OrthoBanc</b> as of
						the date of the payment.
					</p>
					<p className={s.subtitle}>
						I further agree that should <b>OrthoBanc</b> be notified that funds are not available in my bank
						account (NSF, closed account, etc.) or that a charge to my credit/debit card is denied, a $25
						failed payment fee will be charged by <b>OrthoBanc</b>. I agree that if funds are not available
						from the account I choose as primary, <b>OrthoBanc</b> can attempt to secure funds from my
						secondary account. If no secondary account is provided, <b>OrthoBanc</b> can re-draft my primary
						account. I understand if I choose to discontinue this method of payment I must notify{' '}
						<b>OrthoBanc</b> a minimum of 4 business days prior to my scheduled debit date. I also authorize{' '}
						<b>OrthoBanc</b> to contact me at any of the telephone numbers listed above regarding this
						account, including through use of an autodialer, or text or prerecorded messaging. I agree to
						notify <b>OrthoBanc</b> immediately if my cell phone numbers change.
					</p>
					<div className={s.signature}>
						<p className={s.signature_text}>Signature:</p>
						<p className={s.signature_underline}>
							{signature ? (
								<>
									<img
										src={signature}
										alt="Signature"
										height={40}
										width={125}
										className="signature-img"
									/>
								</>
							) : (
								<Popup
									trigger={<button className="signature-button">Add Signature</button>}
									modal
									nested>
									{close => (
										<div className="modal">
											<h2 className="modal-header">Sign here</h2>
											<SignatureCanvas
												ref={sigCanvas}
												penColor="black"
												canvasProps={{ width: 375, height: 120, className: 'sigCanvas' }}
											/>
											<div className="buttons-block">
												<button onClick={save} className="save-button">
													Save
												</button>
												<button onClick={clear} className="clear-button">
													Clear
												</button>
												<button onClick={close} className="close-button">
													Close
												</button>
											</div>
										</div>
									)}
								</Popup>
							)}
						</p>
					</div>
					<div className={s.date}>
						<p className={s.date_text}>Date:</p>
						<p className={s.date_underline}>{getTodayDate()}</p>
					</div>
					<div className={s.oid_table}>
						<div className={s.oid_table_title_row}>For Provider use Only</div>
						<div className={s.oid_table_row}>
							<div className={s.oid_table_row_left}>OID/PID Number</div>
							<div className={s.oid_table_row_right}>of00001439/op00001482</div>
						</div>
					</div>
					<div className={s.address}>
						<p className={s.address_left}>2835 Northpoint Blvd, Hixson TN 37343</p>
						<p className={s.address_right}>RPP - Revision Date Mar - 17</p>
					</div>
					<div className={s.contacts}>P: 800-636-6600, Option 1 F: (888) 758-0586 www.orthobanc.com</div>
				</div>
			</div>
		</>
	);
};

export default MonthlyPaymentDocument;
