import React, { useEffect, useState } from 'react';
import './Header.scss';

import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { getConsults } from '../../store/reducers/ConsultReducer';

const Header = () => {
  const [consults, setConsults] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('user'));
  const { consultations, loading, queryParams } = useSelector(
    (state) => state.consult
  );

  useEffect(() => {
    if (user && user.role === 2) {
      console.log(user);
      if (user.status === 1) {
        navigate(
          `/present/${user.patientId}/${user.createdAt}/${user.treatmentId}`
        );
      } else if (user.status === 2) {
        navigate(
          `/contract/${user.patientId}/${user.createdAt}/${user.treatmentId}`
        );
      }
      if (user.status === 3) {
        navigate(
          `/payment/${user.patientId}/${user.createdAt}/${user.treatmentId}`
        );
      }
      if (user.status === 4) {
        navigate(
          `/monthly-payment/${user.patientId}/${user.createdAt}/${user.treatmentId}`
        );
      }
      if (user.status === 5) {
        navigate(
          `/patient-profile/${user.patientId}/${user.createdAt}/${user.treatmentId}`
        );
      }
      if (user.status === 6) {
        navigate(
          `/patient-profile/${user.patientId}/${user.createdAt}/${user.treatmentId}`
        );
      }
    }
  }, [location]);

  useEffect(() => {
    dispatch(getConsults({ queryParams }));
  }, []);

  useEffect(() => {
    setConsults([...consultations].reverse());
  }, [consultations]);

  return (
    <div className="main-header">
      {user?.role === 0 ? (
        <NavLink
          to={'/info/practice-information'}
          className={({ isActive, isPending }) =>
            isPending
              ? 'header-nav-item'
              : isActive
              ? 'header-active header-nav-item'
              : 'header-nav-item'
          }
        >
          <div className="header-nav-item-info">Settings</div>
        </NavLink>
      ) : (
        <></>
      )}
      <NavLink
        to={`/consultations/${consults.length > 0 ? consults[0].id : ''}`}
        className={({ isActive, isPending }) =>
          isPending
            ? 'header-nav-item'
            : isActive
            ? 'header-active header-nav-item'
            : 'header-nav-item'
        }
      >
        <div className="header-nav-item-info">Consultations</div>
      </NavLink>
      <NavLink
        to={'/profile'}
        className={({ isActive, isPending }) =>
          isPending
            ? 'header-nav-item'
            : isActive
            ? 'header-active header-nav-item'
            : 'header-nav-item'
        }
      >
        <div className="header-nav-item-info">My Profile</div>
      </NavLink>
    </div>
  );
};

export default Header;
