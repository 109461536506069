import React, { useEffect, useRef, useState } from 'react';
import './PatientInfo.scss';

import { useParams } from 'react-router-dom';

import Icon from '../../Icon';
import { useSelector, useDispatch } from 'react-redux';
import { toggleEdit, savePatientData } from '../../store/slices/patientSlice';
import {
  deleteConsult,
  getConsults,
  getConsultsById,
  postConsult,
  sendFaxWithContract,
} from '../../store/reducers/ConsultReducer';
import Loader from '../Loader/Loader';
import {
  getAddOns,
  getDiscounts,
  getLocations,
  getTreatmentTypes,
  getTreatmentTypesAddOns,
} from '../../store/reducers/PracticeInfoReducer';
import { useNavigate } from 'react-router-dom';
import { Toggle } from 'rsuite';
import toast, { Toaster } from 'react-hot-toast';
import {
  getPresentById,
  getSignedContract,
  share,
} from '../../store/reducers/PresentationReducer';
import { stringToPdf } from '../../utils/utils';
import { saveAs } from 'file-saver';
import {
  getSignedMonthlyContract,
  signMonthlyContract,
} from '../../store/reducers/PaymentReducer';
import { pdf, PDFDownloadLink } from '@react-pdf/renderer';
import MonthlyPaymentDownload from '../ContractDocument/MonthlyPaymentDownload';
import { monthlyPaymentDownloadStyles } from '../ContractDocument/styles';

const PatientInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  // const patient = patients.find(patient => consult.id === +id);
  const { currentPatient } = useSelector((state) => state.patient);
  const {
    consult,
    loading,
    queryParams,
    error: consultError,
  } = useSelector((state) => state.consult);
  const { contract, loading: contractDownload } = useSelector(
    (state) => state.presentation
  );
  const { discounts, loading: discountLoading } = useSelector(
    (state) => state.discounts
  );
  const { loading: addOnsLoading, addOns } = useSelector(
    (state) => state.addOns
  );
  const { locations, loading: locationLoading } = useSelector(
    (state) => state.locations
  );
  const { treatmentTypes, loading: treatmentTypesLoading } = useSelector(
    (state) => state.treatmentTypes
  );
  const { monthlyContract, loading: paymentLoading } = useSelector(
    (state) => state.payment
  );
  const { presentation, loading: presentationLoading } = useSelector(
    (state) => state.presentation
  );
  const { treatmentTypeAddOns, loading: treatmentTypeAddOnsLoading } =
    useSelector((state) => state.treatmentTypes);
  const [tretmentsAddOns, setTretmentsAddOns] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [newPatientData, setNewPatientData] = useState(consult);
  const [isEdit, setIsEdit] = useState(false);
  const [contractPdf, setContractPdf] = useState(null);
  const [monthlyPdf, setMonthlyPdf] = useState(null);
  const [presentationInfo, setPresentationInfo] = useState(null);
  const [popup, setPopup] = useState(false);
  const [emailsArray, setEmailsArray] = useState([]);
  const prevTreatmentsCount = useRef(newPatientData?.treatments?.length || 0);

  const genarateColor = () => {
    const getRgb = () => Math.floor(Math.random() * 256);

    const rgbToHex = (r, g, b) =>
      '#' +
      [r, g, b]
        .map((x) => {
          const hex = x.toString(16);
          return hex.length === 1 ? '0' + hex : hex;
        })
        .join('');

    const color = {
      r: getRgb(),
      g: getRgb(),
      b: getRgb(),
    };

    return rgbToHex(color.r, color.g, color.b);
  };

  const newPatient = {
    id: null,
    locationId: '',
    location: {
      name: '',
    },
    discountId: '',
    discount: {
      name: '',
    },
    firstName: 'New',
    lastName: 'Patient',
    patientNumber: '',
    email: '',
    orthoBancNumber: '',
    usedToDate: '',
    maxLifeTime: '',
    percentage: '',
    deductible: '',
    prevContractCredit: '',
    isGuaranteedFunds: false,
    avatarBackground: genarateColor(),
    treatments: [],
    orthoFirstName: '',
    orthoLastName: '',
    orthoEmail: '',
    signLink: '',
    orthoDate: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    phoneNumber: '',
    checking: false,
    saving: false,
    routingNumber: '',
    accountNumber: '',
    cardNumber: '',
    expDate: '',
    cvvCode: '',
  };

  useEffect(() => {
    if (id !== 'new') {
      const fetchData = async () => {
        try {
          await dispatch(getConsultsById({ id })).unwrap();
        } catch (error) {
          console.error('error:', error);
          navigate('/consultations');
        }
      };

      fetchData();
    }
    dispatch(getDiscounts());
    dispatch(getLocations());
    dispatch(getTreatmentTypes());
    dispatch(getAddOns());
  }, [id]);

  useEffect(() => {
    if (id !== 'new') {
      setNewPatientData(consult);
    }
  }, [consult]);

  useEffect(() => {
    if (id !== 'new' && consult.sign) {
      const selectedTreatment = consult.treatments.find(
        (treatment) => treatment.selected === true
      );

      if (selectedTreatment) {
        dispatch(
          getPresentById({
            id,
            date: consult?.createdAt,
            treatmentId: selectedTreatment.treatmentId,
          })
        );
      }
    }
  }, [consult, id]);

  useEffect(() => {
    setPresentationInfo(presentation);
  }, [presentation]);

  useEffect(() => {
    setTreatments(treatmentTypes);
  }, [treatmentTypes]);

  useEffect(() => {
    console.log('newPatientData', newPatientData);
  }, [newPatientData]);

  useEffect(() => {
    if (id === 'new') {
      setNewPatientData(newPatient);
      setIsEdit(true);
    }
  }, [id]);

  useEffect(() => {
    const currentTreatmentsCount = newPatientData?.treatments?.length || 0;
    if (currentTreatmentsCount !== prevTreatmentsCount.current) {
      prevTreatmentsCount.current = currentTreatmentsCount;
      if (
        newPatientData &&
        newPatientData.treatments &&
        newPatientData.treatments.length > 0
      ) {
        let treatmentIds = [];
        newPatientData.treatments.forEach((item) => {
          treatmentIds.push(item.id);
        });
        dispatch(getTreatmentTypesAddOns({ ids: treatmentIds }));
      }
    }
  }, [newPatientData?.treatments]);

  useEffect(() => {
    const filledAddOns = treatmentTypeAddOns.map((item) => {
      const addOn = addOns.find((addOn) => addOn.id === item.addOnId);
      return { ...item, name: addOn.name, fee: addOn.fee, order: addOn.order };
    });

    setTretmentsAddOns(filledAddOns);
  }, [treatmentTypeAddOns]);

  useEffect(() => {
    if (newPatientData?.treatments?.length > 0) {
      const newArray = treatmentTypes.filter((item) => {
        const hasName = newPatientData.treatments.some(
          (item2) => item.name === item2.name
        );
        return !hasName;
      });
      setTreatments(newArray);
    }
  }, [treatmentTypes, newPatientData.treatments]);

  useEffect(() => {
    if (consult && consult?.status > 2 && consult?.contractUrl) {
      dispatch(getSignedContract({ id }));
    }
  }, [consult]);

  useEffect(() => {
    if (consult && consult?.status === 6 && consult?.orthoBancFormUrl) {
      dispatch(getSignedMonthlyContract({ id }));
    }
  }, [consult]);

  useEffect(() => {
    if (contract) {
      const blob = stringToPdf(contract);
      setContractPdf(blob);
    }
  }, [contract]);

  useEffect(() => {
    if (monthlyContract) {
      const blob = stringToPdf(monthlyContract);
      setMonthlyPdf(blob);
    }
  }, [monthlyContract]);

  const handleEditClick = () => {
    if (newPatientData.hasOwnProperty('id')) {
      dispatch(toggleEdit({ isEdit: true, id: newPatientData.id }));
      setIsEdit(true);
    } else {
      setIsEdit(true);
    }
    // dispatch(toggleEdit(consult.id));
  };

  const handleEmail = (e) => {
    const inputText = e.target.value;
    const emails = inputText.split(',').map((email) => email.trim());
    setEmailsArray(emails.filter((email) => email !== ''));
  };

  const handleShare = async () => {
    const data = {
      shareItem: {
        patientId: id,
        emails: emailsArray,
      },
    };

    dispatch(share(data)).then(() => {
      // dispatch(getPresentById({ id, date, treatmentId }))
    });
  };

  const validate = () => {
    let errors = {};

    if (!newPatientData.firstName) {
      errors.firstName = 'First Name is required';
      toast.error('First Name is required');
    }
    if (!newPatientData.lastName) {
      errors.lastName = 'Last Name is required';
      toast.error('Last Name is required');
    }
    if (!newPatientData.patientNumber) {
      errors.patientNumber = 'Patient Number is required';
      toast.error('Patient Number is required');
    }
    if (!newPatientData.email) {
      errors.email = 'Email is required';
      toast.error('Email is required');
    }
    if (!newPatientData.location.name) {
      errors.locationName = 'Location is required';
      toast.error('Location is required');
    }
    // if (!/^\d{7}$/.test(newPatientData.patientNumber)) {
    //   errors.patientNumberCount = 'Patient number must be exactly 7 digits.';
    //   toast.error('Patient number must be exactly 7 digits.');
    // }

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleSaveClick = async (redirect) => {
    const result = validate();
    console.log(result);
    if (result) {
      const combineData = newPatientData?.treatments?.map((treatment) => {
        return {
          treatmentTypeId: treatment?.id,
          selected: treatment.selected,
          addOns:
            treatment?.addOns?.map((addon) => ({
              addOnId: addon.id,
              required: addon.required,
              enabled: addon.required ? true : false,
            })) || [],
        };
      });

      const location = locations.find(
        (obj) => obj.name === newPatientData?.location?.name
      );
      const discount = discounts.find(
        (obj) => obj.name === newPatientData?.discount?.name
      );
      console.log(discount);

      let data = {};

      if (!newPatientData.id) {
        data = {
          patient: {
            locationId: location?.id,
            discountId: discount ? discount.id : 17,
            firstName: newPatientData.firstName,
            lastName: newPatientData.lastName,
            patientNumber: newPatientData.patientNumber,
            email: newPatientData.email,
            orthoBancNumber: newPatientData.orthoBancNumber,
            usedToDate: +newPatientData.usedToDate,
            maxLifeTime: +newPatientData.maxLifeTime,
            percentage: +newPatientData.percentage,
            deductible: +newPatientData.deductible,
            prevContractCredit: +newPatientData.prevContractCredit,
            isGuaranteedFunds: newPatientData.isGuaranteedFunds,
            avatarBackground: newPatientData.avatarBackground,
            treatments: combineData,
            orthoDate: newPatientData?.orthoDate
              ? new Date(newPatientData?.orthoDate).toISOString()
              : null,
            orthoFirstName: '',
            orthoLastName: '',
            orthoEmail: '',
            signLink: '',
            address: '',
            city: '',
            state: '',
            zipCode: '',
            phoneNumber: '',
            checking: false,
            saving: false,
            routingNumber: '',
            accountNumber: '',
            cardNumber: '',
            expDate: '',
            cvvCode: '',
          },
        };
      } else {
        data = {
          patient: {
            id: newPatientData.id,
            locationId: location?.id,
            discountId: discount ? discount.id : 17,
            firstName: newPatientData.firstName,
            lastName: newPatientData.lastName,
            patientNumber: newPatientData.patientNumber,
            email: newPatientData.email,
            orthoBancNumber: newPatientData.orthoBancNumber,
            usedToDate: +newPatientData.usedToDate,
            maxLifeTime: +newPatientData.maxLifeTime,
            percentage: +newPatientData.percentage,
            deductible: +newPatientData.deductible,
            prevContractCredit: +newPatientData.prevContractCredit,
            isGuaranteedFunds: newPatientData.isGuaranteedFunds,
            avatarBackground: newPatientData.avatarBackground,
            treatments: combineData,
            orthoFirstName: newPatientData.orthoFirstName,
            orthoLastName: newPatientData.orthoLastName,
            orthoEmail: newPatientData.orthoEmail,
            signLink: newPatientData.signLink,
            orthoDate: newPatientData?.orthoDate
              ? new Date(newPatientData?.orthoDate).toISOString()
              : null,
            address: newPatientData.address,
            city: newPatientData.city,
            state: newPatientData.state,
            zipCode: newPatientData.zipCode,
            phoneNumber: newPatientData.phoneNumber,
            checking: newPatientData.checking,
            saving: newPatientData.saving,
            routingNumber: newPatientData.routingNumber,
            accountNumber: newPatientData.accountNumber,
            cardNumber: newPatientData.cardNumber,
            expDate: newPatientData.expDate,
            cvvCode: newPatientData.cvvCode,
          },
        };
      }

      console.log(data);
      try {
        await dispatch(postConsult({ ...data })).then((res) => {
          console.log(res);
          if (res.error) {
            toast.error(`Error: ${res.error.message}`);
          } else {
            setIsEdit(false);
            if (id !== 'new') {
              dispatch(getConsultsById({ id })).then(() => {
                if (redirect) {
                  navigate(`/present/${id}/${consult.createdAt}`);
                }
              });
            } else {
              dispatch(getConsults({ queryParams })).then(() => {
                navigate('/consultations');
              });
            }
          }
        });
      } catch (error) {
        toast.error(`Error: ${error.message}`);
      }
    }
  };

  const handleDelete = () => {
    dispatch(deleteConsult({ id: id })).then(() => {
      dispatch(getConsults({ queryParams })).then(() => {
        navigate('/consultations');
      });
    });
  };

  const handlePresent = () => {
    if (isEdit) {
      handleSaveClick(true);
    } else {
      navigate(`/present/${id}/${consult.createdAt}`);
    }
  };

  const onSelectTreatment = (e) => {
    const selectedTreatment = treatmentTypes.find(
      (item) => item.id === +e.target.value
    );
    const updatedTreatments = [...newPatientData.treatments, selectedTreatment];
    setNewPatientData({
      ...newPatientData,
      treatments: updatedTreatments,
    });
  };

  const onSelectAddon = (e) => {
    const addOn = JSON.parse(e.target.value);
    setTretmentsAddOns((prev) =>
      prev.map((item) => {
        if (
          item.addOnId === addOn.addOnId &&
          item.treatmentTypeId === addOn.treatmentTypeId
        ) {
          return { ...item, required: false, enabled: false };
        } else {
          return item;
        }
      })
    );

    setNewPatientData((prev) => {
      const updatedTreatments = prev.treatments.map((treatment) => {
        if (treatment.id === addOn.treatmentTypeId) {
          const newAddOns = treatment.addOns
            ? [
                ...treatment.addOns,
                {
                  ...addOn,
                  id: addOn.addOnId,
                  required: false,
                  enabled: false,
                },
              ]
            : [
                {
                  ...addOn,
                  id: addOn.addOnId,
                  required: false,
                  enabled: false,
                },
              ];
          return { ...treatment, addOns: newAddOns };
        }
        return treatment;
      });
      return { ...prev, treatments: updatedTreatments };
    });
  };

  const onDeleteAddOn = (addOn) => {
    console.log('addOn', addOn);
    setNewPatientData((prev) => {
      const updatedTreatments = prev.treatments.map((treatment) => {
        if (treatment.treatmentId === addOn.treatmentId) {
          const updatedAddOns =
            treatment?.addOns?.filter(
              (existingAddOn) => existingAddOn.id !== addOn.id
            ) ?? [];
          return { ...treatment, addOns: updatedAddOns };
        }
        return treatment;
      });

      return { ...prev, treatments: updatedTreatments };
    });
  };

  const onRequireAddOn = (e) => {
    setNewPatientData((prev) => {
      const updatedTreatments = prev.treatments.map((treatment) => {
        console.log('treatment', treatment);
        console.log('e', e);
        if (e.treatmentTypeId) {
          if (treatment.id === e.treatmentTypeId) {
            const updatedAddOns =
              treatment.addOns?.map((existingAddOn) => {
                if (existingAddOn.id === e.id) {
                  return { ...existingAddOn, required: !e.required };
                }
                return existingAddOn;
              }) ?? [];

            return { ...treatment, addOns: updatedAddOns };
          }
          return treatment;
        } else {
          if (treatment.treatmentId === e.treatmentId) {
            const updatedAddOns =
              treatment.addOns?.map((existingAddOn) => {
                if (existingAddOn.id === e.id) {
                  return { ...existingAddOn, required: !e.required };
                }
                return existingAddOn;
              }) ?? [];

            return { ...treatment, addOns: updatedAddOns };
          }
          return treatment;
        }
      });
      return { ...prev, treatments: updatedTreatments };
    });
  };

  const onDeleteTreatment = (e) => {
    console.log('treatment', e);
    setNewPatientData((prev) => {
      const updatedTreatments = prev.treatments.filter(
        (item) => item.id !== e.id
      );
      return { ...prev, treatments: updatedTreatments };
    });
  };

  const handleContractDownload = () => {
    saveAs(contractPdf, 'document.pdf');
  };

  const handleMonthlyContractDownload = () => {
    saveAs(monthlyPdf, 'orthoBancForm.pdf');
  };

  const handleMonthlyContractSendFax = async () => {
    const blob = await pdf(
      <MonthlyPaymentDownload
        styles={monthlyPaymentDownloadStyles}
        presentationData={presentationInfo}
      />
    ).toBlob();
    console.log(blob);
    dispatch(signMonthlyContract({ file: blob, id: id }))
      .unwrap()
      .then((data) => {
        dispatch(sendFaxWithContract({ id }));
      });
  };

  useEffect(() => {
    console.log('treatments', treatments);
  }, [treatments]);

  if (
    loading ||
    discountLoading ||
    locationLoading ||
    treatmentTypesLoading ||
    treatmentTypeAddOnsLoading ||
    contractDownload ||
    paymentLoading ||
    presentationLoading
  ) {
    return (
      <div className="patient-info-container">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <Toaster position="top-right" />
      <div className={popup ? 'popup' : 'hide-popup'} style={{width : '100%'}}>
        <div className="input-block">
          <div className="popup-close" onClick={() => setPopup(false)}>
            <Icon name="close" styles={{ fill: 'black', width: '20' }}></Icon>
          </div>
          <h5 className="popup-header">Share the presentation</h5>
          <p className="instruction">
            Please, enter the comma-separated list of emails
          </p>
          <p className="example">example1@email.com, example2@email.com, ...</p>
          <textarea
            type="text"
            placeholder={`example1@gmail.com, example2@gmail.com`}
            defaultValue={`${presentation?.email}`}
            onChange={handleEmail}
          />
          <button className="share-button" onClick={handleShare}>
            Share
          </button>
        </div>
      </div>
      <div className="patient-info-container">
        <div className="patient-info-header">
          <h2 className="patient-info-header-name">
            {isEdit ? (
              <>
                <input
                  className="input-firstName input-title"
                  type="text"
                  value={newPatientData.firstName}
                  onChange={(e) =>
                    setNewPatientData({
                      ...newPatientData,
                      firstName: e.target.value,
                    })
                  }
                />
                <input
                  className="input-lastName input-title"
                  type="text"
                  value={newPatientData.lastName}
                  onChange={(e) =>
                    setNewPatientData({
                      ...newPatientData,
                      lastName: e.target.value,
                    })
                  }
                />
              </>
            ) : (
              <>{`${consult.firstName} ${consult.lastName}`}</>
            )}
          </h2>
          <div className="patient-info-header-icons">
            <div
              className="patient-info-header-icons-edit"
              onClick={isEdit ? () => handleSaveClick(false) : handleEditClick}
            >
              <Icon name={isEdit ? 'save' : 'edit'} className="edit" />
            </div>
            <div
              className="patient-info-header-icons-delete"
              onClick={handleDelete}
            >
              <Icon name="delete" />
            </div>
          </div>
        </div>
        <div className="patient-info-block">
          <div
            className={
              isEdit ? 'patient-info-block-row-edit' : 'patient-info-block-row'
            }
          >
            <b>
              Patient Number<b className="required">*</b>:
            </b>
            <p>
              {isEdit ? (
                <input
                  className="input-main-info"
                  type="number"
                  value={newPatientData.patientNumber}
                  onChange={(e) => {
                    setNewPatientData({
                      ...newPatientData,
                      patientNumber: e.target.value,
                    });
                  }}
                />
              ) : (
                consult.patientNumber
              )}
            </p>
          </div>
          <div
            className={
              isEdit ? 'patient-info-block-row-edit' : 'patient-info-block-row'
            }
          >
            <b>OrthoBanc Number:</b>
            <p>
              {isEdit ? (
                <input
                  className="input-main-info"
                  type="text"
                  value={newPatientData.orthoBancNumber}
                  onChange={(e) =>
                    setNewPatientData({
                      ...newPatientData,
                      orthoBancNumber: e.target.value,
                    })
                  }
                />
              ) : (
                consult.orthoBancNumber
              )}
            </p>
          </div>
          <div
            className={
              isEdit ? 'patient-info-block-row-edit' : 'patient-info-block-row'
            }
          >
            <b>
              Email<b className="required">*</b>:
            </b>
            <p>
              {isEdit ? (
                <input
                  className="input-main-info"
                  type="email"
                  value={newPatientData.email}
                  onChange={(e) => {
                    setNewPatientData({
                      ...newPatientData,
                      email: e.target.value,
                    });
                  }}
                />
              ) : (
                consult.email
              )}
            </p>
          </div>
          <div
            className={
              isEdit ? 'patient-info-block-row-edit' : 'patient-info-block-row'
            }
          >
            <b>
              Location<b className="required">*</b>:
            </b>
            <p>
              {isEdit ? (
                <select
                  className="input-main-info"
                  value={newPatientData.location?.name}
                  onChange={(e) => {
                    setNewPatientData({
                      ...newPatientData,
                      location: { name: e.target.value },
                    });
                  }}
                >
                  <option key={''} value="" disabled>
                    Choose Location
                  </option>
                  {[...locations]
                    ?.sort((a, b) => b.order - a.order)
                    ?.map((item) => (
                      <option key={item.id} disabled={!item.isActive}>
                        {item?.name}
                      </option>
                    ))}
                </select>
              ) : (
                consult.location?.name
              )}
            </p>
          </div>
        </div>
        <div className="patient-info-details">
          <div className="patient-info-details-insurance">
            <div className="patient-info-details-insurance-header">
              INSURANCE INFORMATION
            </div>
            <div className="patient-info-details-insurance-info">
              <div
                className={
                  isEdit
                    ? 'patient-info-details-insurance-info-titles-edit'
                    : 'patient-info-details-insurance-info-titles'
                }
              >
                <b>Used to Date:</b>
                <b>Lifetime Maximum:</b>
                <b>Percentage:</b>
                <b>Deductible:</b>
              </div>
              <div className="patient-info-details-insurance-info-details">
                <p>
                  $
                  {isEdit ? (
                    <input
                      className="input-main-info input-mini"
                      type="number"
                      value={newPatientData.usedToDate}
                      onChange={(e) => {
                        setNewPatientData({
                          ...newPatientData,
                          usedToDate: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    consult.usedToDate
                  )}
                </p>
                <p>
                  $
                  {isEdit ? (
                    <input
                      className="input-main-info input-mini"
                      type="number"
                      value={newPatientData.maxLifeTime}
                      onChange={(e) => {
                        setNewPatientData({
                          ...newPatientData,
                          maxLifeTime: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    consult.maxLifeTime
                  )}
                </p>
                <p>
                  {isEdit ? (
                    <input
                      className="input-main-info input-mini"
                      type="number"
                      value={newPatientData.percentage}
                      onChange={(e) =>
                        setNewPatientData({
                          ...newPatientData,
                          percentage: e.target.value,
                        })
                      }
                    />
                  ) : (
                    consult.percentage
                  )}
                  .00%
                </p>
                <p>
                  $
                  {isEdit ? (
                    <input
                      className="input-main-info input-mini"
                      type="number"
                      value={newPatientData.deductible}
                      onChange={(e) =>
                        setNewPatientData({
                          ...newPatientData,
                          deductible: e.target.value,
                        })
                      }
                    />
                  ) : (
                    consult.deductible
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="patient-info-details-payment">
            <div className="patient-info-details-payment-header">
              PAYMENT HISTORY
            </div>
            <div className="patient-info-details-payment-info">
              <div
                className={
                  isEdit
                    ? 'patient-info-details-payment-info-titles-edit'
                    : 'patient-info-details-payment-info-titles'
                }
              >
                <b>Credit from Previous Contract:</b>
                <b>Discount Type:</b>
                <b>Guaranteed Funds Required:</b>
                <b>Banding Date:</b>
              </div>
              <div className="patient-info-details-payment-info-details">
                <p>
                  $
                  {isEdit ? (
                    <input
                      className="input-main-info input-mini"
                      type="number"
                      value={newPatientData.prevContractCredit}
                      onChange={(e) =>
                        setNewPatientData({
                          ...newPatientData,
                          prevContractCredit: e.target.value,
                        })
                      }
                    />
                  ) : (
                    consult.prevContractCredit
                  )}
                </p>
                <p>
                  {isEdit ? (
                    <select
                      className="input-main-info input-medium"
                      value={newPatientData?.discount?.name}
                      onChange={(e) =>
                        setNewPatientData({
                          ...newPatientData,
                          discount: { name: e.target.value },
                        })
                      }
                    >
                      <option value="">No Discount</option>
                      {discounts
                        .filter(
                          (discount) => discount.id !== 17 && discount.isActive
                        )
                        .sort((a, b) => b.order - a.order)
                        .map((item) => (
                          <option key={item.id} disabled={!item.isActive}>
                            {`${item.internalName ? item.internalName : ''} ${
                              item.name
                            }`}
                          </option>
                        ))}
                    </select>
                  ) : (
                    consult.discount?.name
                  )}
                </p>
                <p>
                  {isEdit ? (
                    <input
                      type="checkbox"
                      className="input-main-info input-checkbox-edit"
                      checked={newPatientData.isGuaranteedFunds}
                      onChange={(e) =>
                        setNewPatientData({
                          ...newPatientData,
                          isGuaranteedFunds: e.target.checked,
                        })
                      }
                    />
                  ) : (
                    <Icon
                      name={consult?.isGuaranteedFunds ? 'active' : 'inactive'}
                      styles={
                        consult?.isGuaranteedFunds
                          ? { width: 15, fill: '#26A4ED' }
                          : { width: 15, fill: '#000000' }
                      }
                    />
                  )}
                </p>
                <p>
                  {isEdit ? (
                    <input
                      className="input-main-info"
                      type="date"
                      value={
                        newPatientData?.orthoDate !== null &&
                        newPatientData?.orthoDate !== 'null' &&
                        newPatientData?.orthoDate !== ''
                          ? new Date(newPatientData?.orthoDate)
                              .toISOString()
                              .split('T')[0]
                          : ''
                      }
                      onChange={(e) =>
                        setNewPatientData({
                          ...newPatientData,
                          orthoDate: e.target.value,
                        })
                      }
                    />
                  ) : consult?.orthoDate !== null &&
                    consult?.orthoDate !== '' ? (
                    !isNaN(new Date(consult?.orthoDate)) ? (
                      new Date(consult?.orthoDate).toISOString().split('T')[0]
                    ) : (
                      'Not specified'
                    )
                  ) : (
                    'Not specified'
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="download-contract-block">
          {newPatientData?.status > 2 &&
          newPatientData?.contractUrl &&
          newPatientData.id !== 'new' ? (
            <div className="download-contract-item">
              <div className="download-contract-row">
                <Icon
                  name="document"
                  styles={{ width: '30px', height: '38px' }}
                ></Icon>
                <p className="download-contract-title">Contract</p>
                <div className="download-contract-empty"></div>
              </div>
              <div className="download-contract-button-wrapper">
                <button
                  className="download-contract-button"
                  onClick={handleContractDownload}
                >
                  Download
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
          {newPatientData?.sign &&
          newPatientData.id !== 'new' &&
          presentationInfo?.orthoEmail !== '' &&
          presentationInfo?.orthoEmail !== null ? (
            <div className="download-contract-item">
              <div className="download-contract-row">
                <Icon
                  name="document"
                  styles={{ width: '30px', height: '38px' }}
                ></Icon>
                <p className="download-contract-title">OrthoBanc Form</p>
                <div className="download-contract-empty"></div>
              </div>
              <div className="download-monthly-contract-button-wrapper">
                {/* <button
                  className="download-contract-button"
                  onClick={handleMonthlyContractDownload}
                >
                  Download
                </button> */}
                <PDFDownloadLink
                  className="download-contract-button"
                  style={{ textDecoration: 'none' }}
                  document={
                    <MonthlyPaymentDownload
                      styles={monthlyPaymentDownloadStyles}
                      presentationData={presentationInfo}
                    />
                  }
                  fileName="contract.pdf"
                >
                  {({ blob, url, loading, error }) =>
                    loading ? 'Loading document...' : 'Download PDF'
                  }
                </PDFDownloadLink>
                <button
                  className="download-contract-button"
                  onClick={handleMonthlyContractSendFax}
                >
                  Send
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="patient-info-complex">
          {isEdit && (
            <select
              className="input-main-info treatment-input"
              value=""
              onChange={onSelectTreatment}
            >
              <option value="" disabled hidden>
                Select a treatment...
              </option>
              {[...treatments]
                ?.filter((treatment) => treatment.isActive)
                ?.sort((a, b) => b.order - a.order)
                .map((item) => (
                  <option
                    key={item.id}
                    value={item.id}
                    disabled={!item.isActive}
                  >{`${item.internalName ? item.internalName : ''} ${
                    item.name
                  } - $${item?.fee?.toFixed(2)}`}</option>
                ))}
            </select>
          )}
          {newPatientData?.treatments?.length > 0 ? (
            newPatientData?.treatments
              ?.slice()
              ?.sort((a, b) => b.order - a.order)
              ?.map((treatment) => {
                const addons = tretmentsAddOns.filter(
                  (item) => item.treatmentTypeId === treatment.id
                );
                const filteredAddOns = addons.filter((addon) => {
                  if (treatment?.addOns?.length > 0) {
                    return !treatment?.addOns?.some(
                      (removeAddOn) => removeAddOn.id === addon.addOnId
                    );
                  }
                  return true;
                });
                console.log('filteredAddOns +>>>', filteredAddOns);

                return (
                  <div key={treatment.id}>
                    <div className="patient-info-complex-header">
                      <b>{treatment.name}</b>
                      <p>{`— $${treatment?.fee?.toFixed(2)}`}</p>
                      {isEdit && (
                        <div
                          className="delete-treatment"
                          onClick={() => onDeleteTreatment(treatment)}
                        >
                          <Icon name="delete"></Icon>
                        </div>
                      )}
                    </div>
                    <div className="patient-info-complex-elements">
                      {isEdit && (
                        <>
                          <select
                            className="input-main-info treatment-input"
                            value=""
                            onChange={onSelectAddon}
                          >
                            <option value="" disabled hidden>
                              Select Add-Ons...
                            </option>
                            {filteredAddOns?.length > 0 ? (
                              [...filteredAddOns]
                                ?.sort((a, b) => b.order - a.order)
                                .map((item) => {
                                  return (
                                    <option
                                      key={item.id}
                                      value={JSON.stringify({
                                        ...item,
                                      })}
                                      className="option"
                                    >
                                      <p className="option-name">{`${item.name}  `}</p>
                                      <br />
                                      <p className="option-fee">${item.fee}</p>
                                    </option>
                                  );
                                })
                            ) : (
                              <option value="null" key="none" disabled>
                                No available Add-Ons
                              </option>
                            )}
                          </select>
                          <br />
                        </>
                      )}
                      {treatment?.addOns?.length > 0 ? (
                        [...treatment.addOns]
                          .sort((a, b) => b.order - a.order)
                          .map((item) => {
                            if (
                              item.hasOwnProperty('required') &&
                              item.hasOwnProperty('enabled')
                            ) {
                              return (
                                <div
                                  className={`patient-info-complex-elements-card${
                                    isEdit ? '-edit' : ''
                                  }`}
                                  key={item.id}
                                >
                                  <div className="patient-info-complex-elements-card-main">
                                    <b className="patient-info-complex-elements-card-main-title">
                                      {item.name}
                                    </b>
                                    <div className="patient-info-complex-elements-card-main-price">
                                      ${item.fee}.00
                                    </div>
                                  </div>
                                  {isEdit ? (
                                    <>
                                      <div className="patient-info-complex-elements-card-required-edit">
                                        <p>Set as required</p>
                                        <Toggle
                                          checked={item.required}
                                          onChange={() => onRequireAddOn(item)}
                                        ></Toggle>
                                        <div
                                          className="delete-addons"
                                          onClick={() => onDeleteAddOn(item)}
                                        >
                                          <Icon name="delete"></Icon>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <div
                                      className={`patient-info-complex-elements-card${
                                        item.required
                                          ? '-required'
                                          : '-optional'
                                      }`}
                                    >
                                      {item.required ? 'Required' : 'Optional'}
                                    </div>
                                  )}
                                </div>
                              );
                            } else {
                              return <></>;
                            }
                          })
                      ) : (
                        <>
                          {filteredAddOns?.length > 0
                            ? 'Please select Add-Ons'
                            : 'This Treatment Type does not have Add-Ons'}
                        </>
                      )}
                    </div>
                  </div>
                );
              })
          ) : (
            <></>
          )}
        </div>

        {id === 'new' ? (
          <></>
        ) : consult.status > 2 ? (
          <div className="patient-info-bottom">
            <button
              className="patient-info-bottom-button"
              onClick={() => setPopup(true)}
            >
              Share
            </button>
          </div>
        ) : (
          <div className="patient-info-bottom">
            <button
              className="patient-info-bottom-button"
              onClick={handlePresent}
            >
              {isEdit ? 'Save & Present' : 'Present'}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default PatientInfo;
