import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './ConsultationsSidebar.scss';

import Icon from '../../Icon';
import { NavLink, useNavigate } from 'react-router-dom';

import PatientCard from '../PatientCard/PatientCard';
import { getConsults } from '../../store/reducers/ConsultReducer';
import { setQueryParams } from '../../store/slices/consult/consultSlice';
import Loader from '../Loader/Loader';
import { getLocations } from '../../store/reducers/PracticeInfoReducer';

const ConsultationsSidebar = () => {
	const navigate = useNavigate();
	const [consults, setConsults] = useState([]);
	const [isSettings, setIsSettings] = useState(false);
	const [selectedValues, setSelectedValues] = useState([]);
	const [byName, setByName] = useState(true);
	const [locationId, setLocationId] = useState('');
	const dispatch = useDispatch();
	const { queryParams, consultations, loading } = useSelector(state => state.consult);
	const { locations, loading: locationLoading } = useSelector(state => state.locations);

	const statuses = [
		{ id: 0, name: 'New' },
		{ id: 1, name: 'Presented' },
		{ id: 2, name: 'Accepted' },
		{ id: 3, name: 'Signed' },
		{ id: 4, name: 'Paid' },
		{ id: 5, name: 'Payment Info' },
		{ id: 6, name: 'Completed' }
	];

	const handleAddClick = () => {
		const newPatient = {
			avatarBackground: '#808080',
			createdAt: '',
			firstName: 'New',
			lastName: 'Patient',
			patientNumber: '00000000',
			status: 'New'
		};
		setConsults([newPatient, ...consults]);
		navigate('/consultations/new')
	};

	// useEffect(() => {
	// 	dispatch(getConsults({queryParams}))
	// }, [])

	const handleCheckboxChange = event => {
		const { id, checked } = event.target;
		console.log(id);
		console.log(checked);
		setSelectedValues(prevState => {
			if (checked) {
				return [...prevState, +id];
			} else {
				return prevState.filter(value => value !== +id);
			}
		});
	};

	const handleSelectChange = event => {
		setLocationId(event.target.value);
	};

	const handleApplyClick = () => {
		const selectedIndexesString = selectedValues.length > 0 ? selectedValues.join(',') : '';
		const location = locationId;
		dispatch(setQueryParams({ locationId: location, status: selectedIndexesString }));
	};

	const handleResetClick = () => {
		setSelectedValues([])
		setLocationId('')
		dispatch(setQueryParams({ locationId: '', status: '' }));
	}

	const handleSearch = e => {
		const searchBy = e.target.value.toLowerCase();
		const filteredConsultations = consultations.filter(patient => {
			const fullName = `${patient.firstName} ${patient.lastName}`.toLowerCase();
			const patientNumner = `${patient.patientNumber}`;
			if (byName) {
				return fullName.includes(searchBy);
			} else if (!byName) {
				return patientNumner.includes(searchBy);
			}
			// return fullName.includes(searchBy);
		});
		setConsults(filteredConsultations);
	};

	useEffect(() => {
		if (isSettings) {
			dispatch(getLocations());
		}
	}, [isSettings]);

	useEffect(() => {
		const sortedConsults = [...consultations].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
		setConsults(sortedConsults);
	}, [consultations]);

	if (loading || locationLoading) {
		return (
			<div className="cons-sidebar-container">
				<Loader />
			</div>
		);
	}

	return (
		<div className="cons-sidebar-container">
			<div className="cons-sidebar-header">
				<div className="cons-sidebar-header-search">
					<input type="text" placeholder="Search..." onChange={handleSearch} />
					<div className="cons-sidebar-header-search-select">
						<select
							name="searchBy"
							onChange={e => {
								e.target.value === 'name' ? setByName(true) : setByName(false);
							}}>
							<option value="name">By Name</option>
							<option value="number">By Patient Number</option>
						</select>
					</div>
				</div>
				<div className="cons-sidebar-header-icons">
					{/* <div className="cons-sidebar-header-icons-wrapper">
						<Icon name="search" styles={{ width: '28', fill: '#26A4ED' }} />
					</div> */}
					<div className="cons-sidebar-header-icons-wrapper" onClick={() => setIsSettings(!isSettings)}>
						<Icon name="settings" styles={{ width: '24', fill: '#26A4ED' }} />
					</div>
					<div className="cons-sidebar-header-icons-wrapper" onClick={handleAddClick}>
						<Icon name="plus" styles={{ width: '29', fill: '#26A4ED' }} />
					</div>
				</div>
			</div>
			{isSettings && (
				<div className="cons-sidebar-setting">
					<div className="cons-sidebar-setting-status">
						<h5>STATUS</h5>
						<div className="cons-sidebar-setting-status-inputs">
							{statuses.map(item => (
								<div className="custom-checkbox">
									<input
										className={`custom-checkbox-${item.id}`}
										type="checkbox"
										id={item.id}
										checked={selectedValues.includes(item.id) || false}
										onChange={handleCheckboxChange}
									/>
									<label htmlFor={item.id}>{item.name}</label>
								</div>
							))}

							{/* <div className="custom-checkbox">
								<input type="checkbox" id="1" onChange={handleCheckboxChange} />
								<label htmlFor="1">Presented</label>
							</div>
							<div className="custom-checkbox">
								<input type="checkbox" id="2" onChange={handleCheckboxChange} />
								<label htmlFor="2">Accepted</label>
							</div>
							<div className="custom-checkbox">
								<input type="checkbox" id="3" onChange={handleCheckboxChange} />
								<label htmlFor="3">Signed</label>
							</div>
							<div className="custom-checkbox">
								<input type="checkbox" id="4" onChange={handleCheckboxChange} />
								<label htmlFor="4">Paid</label>
							</div>
							<div className="custom-checkbox">
								<input type="checkbox" id="5" onChange={handleCheckboxChange} />
								<label htmlFor="5">Completed</label>
							</div> */}
						</div>
					</div>
					<div className="cons-sidebar-setting-location">
						<h5>LOCATION</h5>
						<select
							name=""
							id=""
							value={locationId}
							onChange={handleSelectChange}
							className="cons-sidebar-setting-location-selector">
							<option value="">Select...</option>
							{locations?.map(item => (
								<option value={item.id} key={item.id}>
									{item.name}
								</option>
							))}
						</select>
					</div>
					<div className="cons-sidebar-setting-buttons">
						<button onClick={handleApplyClick} className="cons-sidebar-setting-buttons-apply">
							Apply Filters
						</button>
						<button className="cons-sidebar-setting-buttons-reset" onClick={handleResetClick}>Reset Filters</button>
					</div>
				</div>
			)}
			<div className="cons-sidebar-cards">
				{consults.map(patient => (
					<NavLink
						key={patient?.id || 'new'}
						to={`/consultations/${patient.id || 'new'}`}
						className={({ isActive, isPending }) =>
							isActive ? (patient?.isEdit ? 'card-edit card' : 'card-active card') : 'card'
						}>
						{({ isActive, isPending, isTransitioning }) => (
							<PatientCard patient={patient} isActive={isActive} />
						)}
					</NavLink>
				))}
			</div>
		</div>
	);
};

export default ConsultationsSidebar;
